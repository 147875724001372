import './css/app.css'
import EventSchedule from "./components/EventSchedule";
import CustomFooter from './components/CustomFooter';

function App() {

  return (
    <>
      <EventSchedule />
      <CustomFooter />
    </>
  );
}

export default App;
